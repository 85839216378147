import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: { t: TFunction }) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

    return (
      <>
      
        <Extra>
          <Container border={true}>
            <Row
              justify="space-between"
              align="middle"
              style={{ paddingTop: "1rem" }}
            >
              <NavLink to="/">
                {/* <LogoContainer>
                  <SvgIcon
                    src="logo.svg"
                    aria-label="homepage"
                    width="101px"
                    height="64px"
                  />
                </LogoContainer> */}
                <p >Assignment Byte</p>
              <FooterContainer>
                <SocialLink
                  href="https://www.instagram.com/assignment_byte/"
                  src="instagram.svg"
                  />
                                  <a href="mailto:assigmentbyte@gmail.com">
               
                <SocialLink
                  href="mailto:assigmentbyte@gmail.com"
                  src="gmail.svg"
                  />
                  </a>
                {/* <SocialLink
                  href="https://medium.com/@lashakakabadze/"
                  src="medium.svg"
                /> */}
                {/* <a
                  href="https://ko-fi.com/Y8Y7H8BNJ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    height="36"
                    style={{ border: 0, height: 36 }}
                    src="https://storage.ko-fi.com/cdn/kofi3.png?v=3"
                    alt="Buy Me a Coffee at ko-fi.com"
                  />
                </a> */}
              </FooterContainer>
              </NavLink>
            </Row>
          </Container>
        </Extra>
      </>
    );
  };

  export default withTranslation()(Footer);
